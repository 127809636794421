import styles from "./form-progress-icons.module.css";

export default function BagSvg({ complete = false }) {
  return (
    <svg
      //   width="28"
      //   height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.svg}
    >
      <path
        d="M22.4467 6.51015H21.98L18.0367 2.56682C17.7217 2.25182 17.2083 2.25182 16.8817 2.56682C16.5667 2.88182 16.5667 3.39515 16.8817 3.72182L19.67 6.51015H8.33001L11.1183 3.72182C11.4333 3.40682 11.4333 2.89348 11.1183 2.56682C10.8033 2.25182 10.29 2.25182 9.96334 2.56682L6.03168 6.51015H5.56501C4.51501 6.51015 2.33334 6.51015 2.33334 9.49682C2.33334 10.6285 2.56668 11.3751 3.05668 11.8652C3.33668 12.1568 3.67501 12.3085 4.03668 12.3902C4.37501 12.4718 4.73668 12.4835 5.08668 12.4835H22.9133C23.275 12.4835 23.6133 12.4601 23.94 12.3902C24.92 12.1568 25.6667 11.4568 25.6667 9.49682C25.6667 6.51015 23.485 6.51015 22.4467 6.51015Z"
        // fill="blue"
        className={`${styles.svg_color} ${styles[complete.toString()]}`}
      />
      <path
        d="M22.225 14H5.68165C4.95832 14 4.40999 14.6417 4.52665 15.3533L5.50665 21.35C5.83332 23.3567 6.70832 25.6667 10.5933 25.6667H17.1383C21.07 25.6667 21.77 23.695 22.19 21.49L23.3683 15.3883C23.5083 14.665 22.96 14 22.225 14ZM12.3783 21.525C12.3783 21.98 12.0167 22.3417 11.5733 22.3417C11.1183 22.3417 10.7567 21.98 10.7567 21.525V17.675C10.7567 17.2317 11.1183 16.8583 11.5733 16.8583C12.0167 16.8583 12.3783 17.2317 12.3783 17.675V21.525ZM17.3717 21.525C17.3717 21.98 17.01 22.3417 16.555 22.3417C16.1117 22.3417 15.7383 21.98 15.7383 21.525V17.675C15.7383 17.2317 16.1117 16.8583 16.555 16.8583C17.01 16.8583 17.3717 17.2317 17.3717 17.675V21.525Z"
        // fill="blue"
        className={`${styles.svg_color} ${styles[complete.toString()]}`}
      />
    </svg>
  );
}
