import styles from "./form-progress-icons.module.css";

export default function LampChargeSvg({ complete = true }) {
  return (
    <svg
      //   width="28"
      //   height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.svg}
    >
      <path
        d="M17.8033 25.6666C17.7333 25.6666 17.6517 25.655 17.5817 25.6316C15.2367 24.9666 12.775 24.9666 10.43 25.6316C9.99833 25.7483 9.54333 25.5033 9.42667 25.0716C9.29833 24.64 9.555 24.185 9.98667 24.0683C12.6233 23.3216 15.4 23.3216 18.0367 24.0683C18.4683 24.1966 18.725 24.64 18.5967 25.0716C18.48 25.4333 18.1533 25.6666 17.8033 25.6666Z"
        // fill="blue"
        className={`${styles.svg_color} ${styles[complete.toString()]}`}
      />
      <path
        d="M22.4117 7.41977C21.1983 4.96977 18.8533 3.16144 16.135 2.56644C13.2883 1.93644 10.3717 2.6131 8.14333 4.40977C5.90333 6.19477 4.63167 8.86643 4.63167 11.7248C4.63167 14.7464 6.44 17.9081 9.17 19.7398V20.7081C9.15833 21.0348 9.14667 21.5364 9.54333 21.9448C9.95167 22.3648 10.5583 22.4114 11.0367 22.4114H17.0217C17.6517 22.4114 18.13 22.2364 18.4567 21.9098C18.9 21.4548 18.8883 20.8714 18.8767 20.5564V19.7398C22.4933 17.3014 24.7683 12.1564 22.4117 7.41977ZM16.0067 13.5564L14.7583 15.7264C14.595 16.0064 14.3033 16.1698 14 16.1698C13.8483 16.1698 13.6967 16.1348 13.5683 16.0531C13.1483 15.8081 13.0083 15.2714 13.2417 14.8631L14.2333 13.1364H13.2533C12.67 13.1364 12.1917 12.8798 11.935 12.4481C11.6783 12.0048 11.7017 11.4681 11.9933 10.9548L13.2417 8.78477C13.4867 8.36477 14.0233 8.22477 14.4317 8.4581C14.8517 8.7031 14.9917 9.23977 14.7583 9.6481L13.7667 11.3748H14.7467C15.33 11.3748 15.8083 11.6314 16.065 12.0631C16.3217 12.5064 16.2983 13.0548 16.0067 13.5564Z"
        // fill="blue"
        className={`${styles.svg_color} ${styles[complete.toString()]}`}
      />
    </svg>
  );
}
